












































.island-box {
  background-size: 86% auto;
  background-position: center 15%;
  background-repeat: no-repeat;
}
